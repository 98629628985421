import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  root: {
    minHeight: 'inherit'
  },
  outerDiv: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  tabWrapper: {
    borderBottom: 'solid 1px #E5E5E5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    flexWrap: 'wrap'
  },
  tab: {
    fontWeight: 600,
    fontSize: 14,
    textTransform: 'none',
    color: '#949494'
  },
  tabpanel: {
    padding: 24
  }
})

export { useStyles }

import { useEffect, useState } from 'react'
import Logo from '../../assets/images/JoistLogo.png'
import { Auth, Hub } from 'aws-amplify'
import { toast } from 'react-toastify'
import { ROUTES } from '../../config/routes'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { TextInput, Button } from '../../components'
import InputAdornment from '@mui/material/InputAdornment'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Box from '@material-ui/core/Box'
import PasswordIcon from '@mui/icons-material/Password'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import { checkIdp } from '../../store/api'
import { validateEmail } from '../../utils/validations'
import _ from 'lodash'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import Loader from '../../components/Loader'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../config/eventMapping'
import CircularProgress from '@mui/material/CircularProgress'

function decodeErrorMessage(message) {
  const decodedMessage = decodeURIComponent(message)
  return decodedMessage.replace(/\+/g, ' ')
}

export default function App() {
  const [email, setEmail] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const stateParams = searchParams.get('state')
  let initalState = 'signInEmail'
  if (stateParams === 'signup') {
    initalState = 'signUp'
  }
  const [password, setPassword] = useState('')
  const [passwordCnf, setPasswordCnf] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordCnf, setShowPasswordCnf] = useState(false)
  const [name, setName] = useState('')
  const [view, setView] = useState(initalState)
  const navigate = useNavigate()
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(true)
  const [code, setCode] = useState('')
  const [showLoader, setShowLoader] = useState(true)
  const [ssoLoader, setSsoLoader] = useState(false)

  const location = useLocation()
  const { redirectTo } = location.state || { redirectTo: ROUTES.DASHBOARD }

  useEffect(() => {
    const checkSSO = async () => {
      for (const entry of searchParams.entries()) {
        const [param, value] = entry
        if (param === 'sso' && value) {
          setSsoLoader(true)
          const email = 'user@' + value
          const res = await checkIdp({ email: email.toLowerCase() })
          if (res.status === 200) {
            const { identity_provider } = res.data
            if (identity_provider) {
              try {
                Auth.federatedSignIn({
                  customProvider: identity_provider
                }).catch((error) => {
                  toast.error(decodeErrorMessage(error))
                  setSsoLoader(false)
                })
              } catch (error) {
                toast.error(decodeErrorMessage(error))
                console.log(error)
                setSsoLoader(false)
              }
            } else {
              setSsoLoader(false)
              toast.error('Error in SSO')
            }
          } else {
            setSsoLoader(false)
          }
        }
      }
    }

    checkSSO()
  }, [searchParams])

  useEffect(() => {
    const handleAuthEvent = (data) => {
      const { payload = {} } = data
      const { event, data: payloadData = {} } = payload
      const { message = '' } = payloadData || {}
      console.log(event)
      if (event === 'signIn_failure' && message) {
        const msg = decodeErrorMessage(message)
        toast.error(msg)
        setLoading(false)
      } else if (event === 'signIn') {
        const additionalParams = {
          userId: data.payload.data.attributes.sub,
          email: data.payload.data.attributes.email,
          user_name: data.payload.data.attributes.name
        }
        // trackEvent(eventMapping["USER_SIGN_IN"], "SUCCESS", {}, additionalParams);
        window.location.href = redirectTo
      } else if (event === 'signOut') {
        const additionalParams = {
          userId: data.payload.data.attributes.sub,
          email: data.payload.data.attributes.email,
          user_name: data.payload.data.attributes.name
        }
        // trackEvent(eventMapping["USER_SIGN_OUT"], "SUCCESS", {}, additionalParams);
        window.location.href = ROUTES.SIGNIN
      } else {
        setLoading(false)
      }
    }

    Hub.listen('auth', handleAuthEvent)

    return () => {
      Hub.remove('auth', handleAuthEvent)
    }
  }, [redirectTo])

  useEffect(() => {
    async function initalize() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          navigate(redirectTo)
        } else {
          setLoading(true)
        }
      } catch (e) {
        setLoading(false)
      }
    }
    initalize()
    setTimeout(() => {
      setShowLoader(false)
    }, 1000)
  }, [])

  const checkEmail = async (e) => {
    e.preventDefault()
    setLoading(true)
    const errorObj = {}
    if (!validateEmail(email)) {
      errorObj.email = 'Invalid Email Id'
    }
    if (!_.isEmpty(errorObj)) {
      setLoading(false)
      setError(errorObj)
      return
    }
    const res = await checkIdp({ email: email.toLowerCase() })
    if (res.status === 200) {
      const { identity_provider } = res.data
      if (identity_provider) {
        try {
          Auth.federatedSignIn({ customProvider: identity_provider }).catch(
            (error) => {
              toast.error(decodeErrorMessage(error))
            }
          )
        } catch (error) {
          toast.error(decodeErrorMessage(error))
          console.log(error)
        }
      } else {
        toast.error('Error in SSO')
      }
    } else {
      setView('signInEmailPassword')
    }
    setLoading(false)
  }

  const signInUser = async (e) => {
    e.preventDefault()
    setLoading(true)
    const errorObj = {}
    if (!validateEmail(email)) {
      errorObj.email = 'Invalid Email Id'
    }
    if (password.length < 8) {
      errorObj.password = 'Password should have a minimum of 8 characters'
    }
    if (!_.isEmpty(errorObj)) {
      setLoading(false)
      setError(errorObj)
      return
    }
    try {
      Auth.signIn(email.toLowerCase(), password)
    } catch (error) {
      setLoading(false)
      // trackEvent(eventMapping["USER_SIGN_IN"], "FAILED", {}, { 'id': email.toLowerCase() });
      toast.error(decodeErrorMessage(error))
    }
  }

  const createUser = async (e) => {
    e.preventDefault()
    setLoading(true)
    const errorObj = {}
    if (!validateEmail(email)) {
      errorObj.email = 'Invalid Email Id'
    }
    if (password.length < 8) {
      errorObj.password = 'Password should have a minimum of 8 characters'
    } else if (
      // eslint-disable-next-line no-useless-escape
      !/(?=.*\d)(?=.*[!@$%^&*()_+={}\[\]:;<>,.?/"'\\|`~-])/g.test(password) ||
      !/(?=.*[a-z])(?=.*[A-Z])/.test(password)
    ) {
      errorObj.password =
        'Password must contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter'
    }
    if (password !== passwordCnf) {
      errorObj.passwordCnf = 'Passwords does not match'
    }
    if (!name) {
      errorObj.name = 'Name is required'
    }
    if (!_.isEmpty(errorObj)) {
      setLoading(false)
      setError(errorObj)
      return
    }
    try {
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name
        },
        autoSignIn: {
          enabled: true
        }
      })
        .then(() => {
          setView('codeVerify')
        })
        .catch((error) => {
          toast.error(decodeErrorMessage(error))
          setLoading(false)
        })
    } catch (error) {
      toast.error(decodeErrorMessage(error))
      console.log(error)
      setLoading(false)
    }
  }

  const forgetPassword = (e) => {
    e.preventDefault()
    setLoading(true)
    const errorObj = {}
    if (!validateEmail(email)) {
      errorObj.email = 'Invalid Email Id'
    }
    if (!_.isEmpty(errorObj)) {
      setLoading(false)
      setError(errorObj)
      return
    }
    try {
      Auth.forgotPassword(email.toLowerCase())
        .then(() => {
          setView('forgotPasswordSent')
        })
        .catch((error) => {
          toast.error(decodeErrorMessage(error))
          setLoading(false)
        })
    } catch (error) {
      toast.error(decodeErrorMessage(error))
      console.log(error)
      setLoading(false)
    }
  }

  const resentConfirmation = (e) => {
    e.preventDefault()
    setLoading(true)
    const errorObj = {}
    if (!validateEmail(email)) {
      errorObj.email = 'Invalid Email Id'
    }
    if (!_.isEmpty(errorObj)) {
      setLoading(false)
      setError(errorObj)
      return
    }
    try {
      Auth.resendSignUp(email.toLowerCase())
        .then(() => {
          setView('resendCodeVerify')
        })
        .catch((error) => {
          toast.error(decodeErrorMessage(error))
          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      toast.error(decodeErrorMessage(error))
      setLoading(false)
    }
  }

  const changePassword = (e) => {
    e.preventDefault()
    setLoading(true)
    const errorObj = {}
    if (!validateEmail(email)) {
      errorObj.email = 'Invalid Email Id'
    }
    if (code.length < 6) {
      errorObj.password = 'Invalid verification code'
    }
    if (password.length < 8) {
      errorObj.password = 'Password should have a minimum of 8 characters'
    }
    if (password !== passwordCnf) {
      errorObj.passwordCnf = 'Passwords does not match'
    }
    if (!_.isEmpty(errorObj)) {
      setLoading(false)
      setError(errorObj)
      return
    }
    try {
      Auth.forgotPasswordSubmit(email.toLowerCase(), code, password)
        .then(() => {
          setView('signInEmailPassword')
        })
        .catch((error) => {
          toast.error(decodeErrorMessage(error))
          setLoading(false)
        })
    } catch (error) {
      toast.error(decodeErrorMessage(error))
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    setPassword('')
    setPasswordCnf('')
    setShowPassword('')
    setShowPasswordCnf('')
    if (
      ![
        'signInEmailPassword',
        'forgetPassword',
        'resendUserVerify',
        'forgotPasswordSent'
      ].includes(view)
    ) {
      setEmail('')
    }
    setName('')
    setError({})
    setCode('')
  }, [view])

  useEffect(() => {
    if (error?.email) {
      setError({ ...error, email: '' })
    }
  }, [email])

  useEffect(() => {
    if (error?.password) {
      setError({ ...error, password: '' })
    }
  }, [password])

  useEffect(() => {
    if (error?.passwordCnf) {
      setError({ ...error, passwordCnf: '' })
    }
  }, [passwordCnf])

  useEffect(() => {
    if (error?.name) {
      setError({ ...error, name: '' })
    }
  }, [name])

  useEffect(() => {
    if (error?.code) {
      setError({ ...error, code: '' })
    }
  }, [code])

  const renderForms = () => {
    switch (view) {
      case 'signInEmail':
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Login with email address
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                value={email}
                id={'email'}
                type="email"
                autoComplete="email"
                error={error?.email}
                helperText={error?.email}
                placeholder="Email Address"
                handleChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <Button
                type="submit"
                variation="primary"
                sx={{ width: '100%' }}
                disabled={loading}
                onClick={(e) => checkEmail(e)}
              >
                {loading ? <CircularProgress size={24.5} /> : 'Next'}
              </Button>
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  onClick={() => setView('signUp')}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  Don't have an account?
                </span>
              </Box>
              <Box>
                <span
                  onClick={(e) => setView('signUp')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Get Started
                </span>
              </Box>
            </Box>
          </Box>
        )
      case 'signInEmailPassword':
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Login with email address
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <input
                type="email"
                name="fakeemail" // Hidden field
                style={{ display: 'none' }}
              />
              <TextInput
                id={'email'}
                type="email"
                autoComplete="off"
                value={email}
                error={error?.email}
                helperText={error?.email}
                placeholder="Email Address"
                handleChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'password'}
                type={showPassword ? 'text' : 'password'}
                value={password}
                error={error?.password}
                helperText={error?.password}
                autoComplete="current-password"
                placeholder="Password"
                handleChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <Button
                type="submit"
                variation="primary"
                sx={{ width: '100%' }}
                disabled={loading}
                onClick={(e) => signInUser(e)}
              >
                {loading ? <CircularProgress size={24.5} /> : 'Sign In'}
              </Button>
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  onClick={() => setView('forgetPassword')}
                  style={{
                    cursor: 'pointer',
                    color: '#2948FF'
                  }}
                >
                  Forgot Password
                </span>
              </Box>
              <Box>Don't have an account?</Box>
              <Box>
                <span
                  onClick={(e) => setView('signUp')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Get Started
                </span>
              </Box>
            </Box>
          </Box>
        )
      case 'signUp':
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Create a new account
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'name'}
                value={name}
                type="text"
                autoComplete="off"
                error={error?.name}
                helperText={error?.name}
                placeholder="Full Name"
                handleChange={(e) => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBoxOutlinedIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'email'}
                type="email"
                autoComplete="email"
                value={email}
                error={error?.email}
                helperText={error?.email}
                placeholder="Email Address"
                handleChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'password'}
                value={password}
                error={error?.password}
                helperText={error?.password}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                handleChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'passwordcnf'}
                value={passwordCnf}
                error={error?.passwordCnf}
                helperText={error?.passwordCnf}
                autoComplete="new-password"
                type={showPasswordCnf ? 'text' : 'password'}
                placeholder="Confirm Password"
                handleChange={(e) => setPasswordCnf(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPasswordCnf(!showPasswordCnf)}
                      >
                        {showPasswordCnf ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <Button
                type="submit"
                variation="primary"
                sx={{ width: '100%' }}
                disabled={loading}
                onClick={(e) => createUser(e)}
              >
                {loading ? <CircularProgress size={24.5} /> : 'Create User'}
              </Button>
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>Already have an account?</Box>
              <Box>
                <span
                  onClick={(e) => setView('signInEmail')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Login
                </span>
              </Box>
              <Box>
                <span
                  onClick={(e) => setView('resendUserVerify')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Resend Verification
                </span>
              </Box>
            </Box>
          </Box>
        )
      case 'forgetPassword':
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Forgot Password
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'email'}
                value={email}
                error={error?.email}
                helperText={error?.email}
                type="email"
                autoComplete="email"
                placeholder="Email Address"
                handleChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <Button
                type="submit"
                variation="primary"
                sx={{ width: '100%' }}
                disabled={loading}
                onClick={(e) => forgetPassword(e)}
              >
                {loading ? <CircularProgress size={24.5} /> : 'Send Reset Link'}
              </Button>
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => setView('signInEmail')}
                >
                  Already have an account?
                </span>
              </Box>
              <Box>
                <span
                  onClick={(e) => setView('signInEmail')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Sign In
                </span>
              </Box>
            </Box>
          </Box>
        )

      case 'forgotPasswordSent':
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Password reset Code Sent.
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              We have sent a password reset code by email. Enter it below to
              reset your password.
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'code'}
                value={code}
                type="text"
                autoComplete="one-time-code"
                error={error?.code}
                helperText={error?.code}
                placeholder="Code"
                handleChange={(e) => setCode(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SecurityOutlinedIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'password'}
                value={password}
                error={error?.password}
                helperText={error?.password}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                handleChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'passwordcnf'}
                value={passwordCnf}
                error={error?.passwordCnf}
                helperText={error?.passwordCnf}
                autoComplete="new-password"
                type={showPasswordCnf ? 'text' : 'password'}
                placeholder="Confirm Password"
                handleChange={(e) => setPasswordCnf(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPasswordCnf(!showPasswordCnf)}
                      >
                        {showPasswordCnf ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <Button
                type="submit"
                variation="primary"
                sx={{ width: '100%' }}
                disabled={loading}
                onClick={(e) => changePassword(e)}
              >
                {loading ? <CircularProgress size={24.5} /> : 'Change Password'}
              </Button>
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  onClick={(e) => setView('signInEmail')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Sign In
                </span>
              </Box>
            </Box>
          </Box>
        )

      case 'codeVerify':
        return (
          <>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Verification link sent to your email address.
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              It may take a minute to arrive. Please check your inbox and click
              the link to complete sign-up.
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  onClick={(e) => setView('resendUserVerify')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Resend Verification
                </span>
              </Box>
              <Box>
                <span
                  onClick={(e) => setView('signInEmail')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Sign In
                </span>
              </Box>
            </Box>
          </>
        )

      case 'resendCodeVerify':
        return (
          <>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Verification link has been sent to your email id.
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              It may take a minute to arrive. Please check your inbox and click
              the link to complete sign-up.
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  onClick={(e) => setView('resendUserVerify')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Re-Send Verification
                </span>
              </Box>
              <Box>
                <span
                  onClick={(e) => setView('signInEmail')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Sign In
                </span>
              </Box>
            </Box>
          </>
        )
      case 'resendUserVerify':
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              Re-Send User Verification
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <TextInput
                id={'email'}
                value={email}
                error={error?.email}
                helperText={error?.email}
                placeholder="Email Address"
                handleChange={(e) => setEmail(e.target.value)}
                type="email"
                autoComplete="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                margin: '25px 0px'
              }}
            >
              <Button
                type="submit"
                variation="primary"
                sx={{ width: '100%' }}
                disabled={loading}
                onClick={(e) => resentConfirmation(e)}
              >
                {loading ? <CircularProgress size={24.5} /> : 'Re-Send'}
              </Button>
            </Box>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                margin: '25px 0px'
              }}
            >
              <Box>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => setView('signInEmail')}
                >
                  Already have an account?
                </span>
              </Box>
              <Box>
                <span
                  onClick={(e) => setView('signInEmail')}
                  style={{ color: '#2948FF', cursor: 'pointer' }}
                >
                  Sign In
                </span>
              </Box>
            </Box>
          </Box>
        )
      default:
        break
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        fontFamily: 'PoppinsRegular',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {showLoader || ssoLoader ? (
        <Loader loading={showLoader || ssoLoader} caption={''} />
      ) : (
        <Box
          style={{
            minHeight: '400px',
            width: '330px',
            padding: '25px 50px',
            border: '1px solid #EEEEEE',
            boxShadow: '1px 1px 5px 5px rgba(0,0,0,0.15)',
            borderRadius: '3px',
            textAlign: 'center',
            fontFamily: 'PoppinsRegular',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '22px'
          }}
        >
          <Box>
            <Box sx={{ margin: '10px' }}>
              <img
                src={Logo}
                alt="applogo"
                style={{ width: '200px', padding: '20px 0px' }}
              />
            </Box>
            {renderForms()}
          </Box>
        </Box>
      )}
    </Box>
  )
}
